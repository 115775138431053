<style lang="less" scoped>
  @import "../../assets/css/variables";

  .customer-detail {
    // width: 40%;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-bottom: 50px;
    top: 15%;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/customer/list' }">
        客户管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customer/list' }">
        客户列表
      </el-breadcrumb-item>
      {{ this.pubNumberList }}
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getCustomerByName">
      <el-col :span="24" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 200px"
              v-model="filters.key"
              placeholder="单位名称/联系人/联系电话"
            ></el-input>
          </el-form-item>

          <el-form-item v-if="isAdmin">
            <el-select
              v-model="filters.isProxy"
              placeholder="是品牌商或者是经销商"
            >
              <el-option label="品牌商" :value="true"></el-option>
              <el-option label="经销商" :value="false"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="getCustomerByName">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="customerList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          width="150"
          :show-overflow-tooltip="true"
          label="单位名称"
        ></el-table-column>

        <el-table-column
          prop="contact"
          label="联系人"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column
          prop="contactTel"
          :show-overflow-tooltip="true"
          width="130"
          label="联系电话"
        ></el-table-column>

        <el-table-column
          prop="address"
          :show-overflow-tooltip="true"
          label="公司地址"
        ></el-table-column>

        <el-table-column label="操作" width="320">
          <template slot-scope="scope">
            <el-button
              @click="goTo('detail/' + scope.row.id)"
              :disabled="!editPermission"
              size="small"
            >
              修改
            </el-button>

            <el-button
              @click="goTo('/customer/list/' + scope.row.id)"
              size="small"
              type="info"
              class="m-l-xs"
            >
              详情
            </el-button>

            <!-- scope.row.id不为产品列表id，需要在数据中加一个产品列表id，在用产品列表id将下面这个代替 -->
            <el-button
              @click="goTo('product/' + scope.row.publicNumberId)"
              :disabled="!editPermission"
              size="small"
            >
              产品列表
            </el-button>

            <el-button
              @click="goTo('customer-detail/' + scope.row.id)"
              :disabled="!editPermission"
              size="small"
            >
              公众号
            </el-button>

            <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <router-link :to="{ path: 'detail' }">
          <el-button :disabled="!editPermission" type="primary">
            新增客户
          </el-button>
        </router-link>
        <!-- <el-button :disabled="!editPermission" type="primary" @click="customerDetailVisible=true">新增客户</el-button> -->
        <el-button
          type="danger"
          @click="batchRemove"
          :disabled="!this.sels.length > 0 || !editPermission"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
    <el-dialog
      :title="isEdit ? '修改客户信息' : '新增客户'"
      size="big"
      :visible.sync="customerDetailVisible"
    >
      <customer-detail
        :isEdit="isEdit"
        :propCustomerInfo="customerInfo"
        v-on:onSubmit="customerDetailSubmit"
        :isRootLevel="true"
        :customerDetailVisible="customerDetailVisible"
      ></customer-detail>
    </el-dialog>
  </div>
</template>

<script>
  import { searchPubNumberByKey, delPubNumber } from "../../services/customer";
  import {
    getAllCustomers,
    removeCustomer,
    disabledCustomer,
    enabledCustomer,
  } from "../../services/customer";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import CustomerDetail from "./detail.vue";
  import { mapGetters, mapActions } from "vuex";
  let tempKey = null;

  export default {
    beforeRouteEnter(to, from, next) {
      if (from.query.searchKey) {
        tempKey = from.query.searchKey;
      } else {
        tempKey = null;
      }
      next();
    },
    data() {
      return {
        editPermission: true,
        isEdit: false,
        isAdmin: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        customerList: [],
        filters: {
          key: "",
          isProxy: null,
        },
        customerInfo: {
          name: "",
          contact: "",
          contactTel: "",
          isProxy: false,
          proxy: "",
          address: "",
          remarks: "",
          outChain: "",
          storeAddress: "",
          productUrl: "",
        },
        listLoading: false,
        customerDetailVisible: false,
        sels: [], //列表选中列
      };
    },
    components: {
      "customer-detail": CustomerDetail,
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      goTo(path) {
        this.$router.push({
          path,
          query: {
            searchKey: this.filters.key || null,
          },
        });
      },

      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeCustomer(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryCustomers();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.customerDetailVisible = true;
        // row;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedCustomer = Object.assign({}, row);
        // console.log(Object.assign)
        this.customerInfo = {
          id: choosedCustomer.id,
          name: choosedCustomer.name,
          contact: choosedCustomer.contact,
          contactTel: choosedCustomer.contactTel,
          isProxy: choosedCustomer.isProxy,
          address: choosedCustomer.address,
          remarks: choosedCustomer.remarks,
          proxy: choosedCustomer.proxy,
          outChain: choosedCustomer.outChain,
          storeAddress: choosedCustomer.storeAddress,
          productUrl: choosedCustomer.productUrl,
        };
      },

      /**
       *客户修改或者新增成功
       * @param isCreate true 创建  false 修改
       */
      customerDetailSubmit(customerData) {
        if (this.isEdit) {
          this.customerList.splice(this.choosedRowIndex, 1, customerData);
        } else {
          this.total++;
          //这里的逻辑是倒序的，根据createTime倒序 取最新值。所以默认插入到第一行
          if (this.pager.page == 0) {
            // Math.floor(this.total / this.pager.size)) {
            this.customerList.unshift(customerData);

            if (this.total % PAGE_SIZE === 1) {
              this.customerList.pop();
              //              this.pager.page++;
            }
          }
        }
        this.customerDetailVisible = false;
        return false;
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeCustomer(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryCustomers();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据客户名筛选
       */
      getCustomerByName() {
        this.queryCustomers();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.queryCustomers();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryCustomers();
      },

      /**
       * 获取所有的客户
       */
      async queryCustomers() {
        this.listLoading = true;
        let searchParam = Object.assign({}, this.filters);

        let responseData = await getAllCustomers(searchParam, this.pager);

        if (responseData.errorCode === 0) {
          this.customerList = responseData.data.list || [];
          // console.log('this.customerList',this.customerList)
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },

      getPubListByKey() {
        this.queryPubNumbers();
      },

      /**
       * 获取所有的客户
       */
      async queryPubNumbers() {
        this.listLoading = true;
        let customers = Object.assign({}, this.filters);
        const responseData = await searchPubNumberByKey(customers, this.pager);
        // const responseData = await searchPubNumberByKey();
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.pubNumberList = responseData.data.datas || [];
          console.log("this.pubNumberList", this.pubNumberList);
          this.total = responseData.data.totalCount;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      const role = this.user.roleCode;
      if (/admin/.test(role)) {
        this.isAdmin = true;
      }
      this.filters.key = tempKey;
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryCustomers();
    },
    watch: {
      customerDetailVisible(newVal, oldVal) {
        if (newVal === false) {
          this.isEdit = false;
        }
      },
    },
  };
</script>
